<template>
  <div class="d-flex flex-wrap align-items-start">
    <b-form-group class="mb-0">
      <div class="d-flex">
        <div
          v-if="currencySymbol"
          class="d-inline-flex mr-50"
          style="padding-top: 10px"
        >
          {{ currencySymbol }}
        </div>
        <validation-provider
          #default="{ errors }"
          name="price"
          rules="integer_double"
          :vid="`${offerId}_price`"
          class="d-inline-flex flex-column"
        >
          <b-form-input
            style="width: 108px"
            :value="offerData.price"
            placeholder="0.00"
            :state="errors.length ? false : null"
            @input="$emit('updateValue',[offerId, 'price', $event])"
            @blur="$emit('save')"
          />
          <small
            v-if="errors.length"
            class="text-danger"
          >
            {{ $t('Not a number') }}
          </small>
        </validation-provider>
      </div>
    </b-form-group>
    <span
      class="ml-25 mr-25"
      style="padding-top: 10px"
    >
      /
    </span>
    <validation-provider
      #default="{ errors }"
      name="amount"
      :rules="offerData.measurementUnit === 'portion' ? '' : 'required|integer_double_fraction'"
      :vid="`${offerId}_amount`"
    >
      <b-input-group style="width: 138px">
        <b-form-input
          v-if="offerData.measurementUnit !== 'portion'"
          placeholder="0"
          size="md"
          :value="offerData.amount"
          :state="errors.length ? false : null"
          :disabled="offerData.measurementUnit === 'portion'"
          @input="$emit('updateValue',[offerId, 'amount', $event])"
          @blur="$emit('save')"
        />
        <b-input-group-append
          :style="offerData.measurementUnit === 'portion' ? 'width: 100%; margin-left: 0' : 'min-width: 73px'"
        >
          <b-button
            :class="[
              'w-100 measurement-unit',
              { 'measurement-unit_portion' : offerData.measurementUnit === 'portion' },
            ]"
            size="md"
            variant="outline-primary"
            @click="onClickMeasurementUnit"
          >
            <template v-if="offerData.measurementUnit === 'portion'">
              {{ $t('measurementUnitPortion') }}
            </template>
            <template v-else-if="offerData.measurementUnit === 'items'">
              {{ $t('measurementUnitItems') }}
            </template>
            <template v-else>
              {{ offerData.measurementUnit }}
            </template>
          </b-button>
        </b-input-group-append>
      </b-input-group>
      <small
        v-if="errors.length && offerData.measurementUnit !== 'portion'"
        class="d-block text-danger"
      >
        {{ $t('Not a number') }}
      </small>
    </validation-provider>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { ValidationProvider } from 'vee-validate'
import { required, integerDouble, integerDoubleFraction } from '@validations'
import {
  BButton,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
} from 'bootstrap-vue'

export default {
  name: 'Offer',
  components: {
    ValidationProvider,
    BButton,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
  },
  props: {
    offerId: {
      type: String,
      required: true,
      default: '',
    },
    offerData: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      // validation rules
      required,
      integerDouble,
      integerDoubleFraction,
    }
  },
  computed: {
    ...mapGetters({
      currencySymbol: 'restaurant/currencySymbol',
      measurementUnitParams: 'restaurant/measurementUnitParams',
    }),
  },
  methods: {
    onClickMeasurementUnit() {
      let measurementUnit = ''
      const measurementUnitIndex = this.measurementUnitParams.findIndex(i => i === this.offerData.measurementUnit)

      if (measurementUnitIndex < this.measurementUnitParams.length - 1) {
        measurementUnit = this.measurementUnitParams[measurementUnitIndex + 1]
      } else {
        [measurementUnit] = this.measurementUnitParams
      }

      this.$emit('updateValue', [this.offerId, 'measurementUnit', measurementUnit])
      this.$emit('save')
    },
  },
}
</script>

<style lang="sass">
  @import '@core/scss/base/bootstrap-extended/_variables.scss'

  .measurement-unit
    padding-left: 0
    padding-right: 0
    &_portion
      border-top-left-radius: $border-radius !important
      border-bottom-left-radius: $border-radius !important
      width: 100%
</style>
