<template>
  <b-card :title="$t('cardPricingTitle')">
    <b-button
      variant="outline-primary"
      @click="onClickAddToMenuOpen"
    >
      {{ $t('Add to Menu') }}
    </b-button>
    <card-pricing-item
      v-for="(offerTree, offerTreeIndex) in value"
      :key="offerTree.id"
      :class="[offerTreeIndex === 0 ? 'mt-2' : 'mt-75']"
      :offer-tree="offerTree"
      @updateValue="onUpdateValue($event, offerTree.id)"
      @delete="onDelete(offerTree)"
    />
    <b-modal
      v-model="addToMenu.isModal"
      centered
      hide-header
      hide-footer
    >
      <div class="pt-50 pb-50">
        <h4 class="text-center mb-2">
          {{ $t('Add to Menu') }}
        </h4>
        <div>
          <h5>{{ $t('Menu') }}</h5>
          <div
            v-if="menusToAdd.length"
            class="mt-1 d-flex align-items-center"
          >
            <b-form-radio
              v-model="addToMenu.menuType"
              value="select"
              :disabled="addToMenu.isInProgress"
              @click.native="onClickAddToMenuMenuType('select')"
            >
              {{ $t('Existent') }}
            </b-form-radio>
            <b-form-radio
              v-model="addToMenu.menuType"
              value="new"
              :disabled="addToMenu.isInProgress"
              class="ml-2"
              @click.native="onClickAddToMenuMenuType('new')"
            >
              {{ $t('New') }}
            </b-form-radio>
          </div>
          <div class="mt-1">
            <template v-if="addToMenu.menuType === 'select'">
              <b-form-radio
                v-for="menu in menusToAdd"
                :key="menu.id"
                v-model="addToMenu.menuId"
                class="my-25"
                :value="menu.id"
                :disabled="addToMenu.isInProgress"
              >
                {{ menu.name }}
              </b-form-radio>
            </template>
            <template v-if="addToMenu.menuType === 'new'">
              <b-form-input
                id="input-menu-name"
                v-model="addToMenu.menuName"
                :disabled="addToMenu.isInProgress"
                :readonly="isCreateMenuDisabledWithoutPlan || isCreateMenuDisabledWithPlan"
                :placeholder="$t('New menu name')"
              />
              <tooltip-create-menu
                target="input-menu-name"
                triggers="hover"
              />
            </template>
          </div>
        </div>
        <template v-if="addToMenu.menuId || addToMenu.menuName">
          <hr class="mt-2 mb-2">
          <div>
            <h5>{{ $t('Category') }}</h5>
            <div
              v-if="categoriesToAdd.length"
              class="mt-1 d-flex align-items-center"
            >
              <b-form-radio
                v-model="addToMenu.categoryType"
                value="select"
                :disabled="addToMenu.isInProgress"
              >
                {{ $t('Existent') }}
              </b-form-radio>
              <b-form-radio
                v-model="addToMenu.categoryType"
                value="new"
                :disabled="addToMenu.isInProgress"
                class="ml-2"
              >
                {{ $t('New') }}
              </b-form-radio>
            </div>
            <div class="mt-1">
              <template v-if="addToMenu.categoryType === 'select'">
                <b-form-radio
                  v-for="category in categoriesToAdd"
                  :key="category.id"
                  v-model="addToMenu.categoryId"
                  class="my-25"
                  :value="category.id"
                  :disabled="addToMenu.isInProgress"
                >
                  {{ category.name }}
                </b-form-radio>
              </template>
              <template v-if="addToMenu.categoryType === 'new'">
                <b-form-input
                  v-model="addToMenu.categoryName"
                  :disabled="addToMenu.isInProgress"
                  :placeholder="$t('menuNewCategoryPlaceholder')"
                />
              </template>
            </div>
          </div>
        </template>
        <div class="mt-2 d-flex justify-content-center">
          <b-button
            variant="primary"
            :disabled="isAddToMenuSaveDisabled"
            @click="onClickAddToMenuSave"
          >
            <b-spinner
              v-if="addToMenu.isInProgress"
              class="d-flex"
              small
            />
            <template v-else>
              {{ $t('Save') }}
            </template>
          </b-button>
          <b-button
            class="ml-1"
            variant="outline-primary"
            :disabled="addToMenu.isInProgress"
            @click="addToMenu.isModal = false"
          >
            {{ $t('Cancel') }}
          </b-button>
        </div>
      </div>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard,
  BModal,
  BFormRadio,
  BSpinner,
  BFormInput,
  BButton,
  VBTooltip,
} from 'bootstrap-vue'
import { mapGetters, mapActions } from 'vuex'

import CardPricingItem from '@/components/CardPricingItem.vue'
import TooltipCreateMenu from '@/components/tooltip/TooltipCreateMenu.vue'

import mixinRequests from '@/mixins/requests'
import mixinFormatter from '@/mixins/formatter'
import mixinTooltipCreateMenu from '@/mixins/tooltip/tooltip-create-menu'
import mixinGqlCategory from '@/mixins/gql/category'
import mixinOffer from '@/mixins/offer'

import UPDATE_MENU from '@/gql/mutation/menu/updateMenu.gql'

export default {
  name: 'CardPricing',
  components: {
    BCard,
    BModal,
    BFormRadio,
    BSpinner,
    BFormInput,
    BButton,
    CardPricingItem,
    TooltipCreateMenu,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [
    mixinRequests,
    mixinFormatter,
    mixinTooltipCreateMenu,
    mixinGqlCategory,
    mixinOffer,
  ],
  props: {
    value: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      addToMenu: {
        menuType: 'new',
        menuName: '',
        menuId: null,

        categoryType: 'new',
        categoryName: '',
        categoryId: null,

        isModal: false,
        isInProgress: false,
      },
    }
  },
  computed: {
    ...mapGetters({
      menus: 'menuManagement/menus',
    }),
    menusToAdd() {
      return this.menus.filter(i => !this.value.find(j => j.id === i.id))
    },
    categoriesToAdd() {
      if (this.addToMenu.menuId === null) {
        return []
      }

      const menu = this.menus.find(i => i.id === this.addToMenu.menuId)
      if (!menu) {
        return []
      }

      return menu.categories
    },
    isAddToMenuSaveDisabled() {
      return this.addToMenu.isInProgress
        || (this.addToMenu.menuType === 'new' && this.addToMenu.menuName === '')
        || (this.addToMenu.menuType === 'select' && this.addToMenu.menuId === null)
        || (this.addToMenu.categoryType === 'new' && this.addToMenu.categoryName === '')
        || (this.addToMenu.categoryType === 'select' && this.addToMenu.categoryId === null)
    },
    // used in watch
    addToMenuId() {
      return this.addToMenu.menuId
    },
  },
  watch: {
    addToMenuId() {
      this.addToMenu.categoryType = this.categoriesToAdd.length ? 'select' : 'new'
      this.addToMenu.categoryName = ''
      this.addToMenu.categoryId = null
    },
  },
  methods: {
    ...mapActions({
      createMenu: 'menuManagement/createMenu',
      getMenus: 'menuManagement/getMenus',
      getMenuItems: 'menuManagement/getMenuItems',
      updateMenu: 'menuManagement/updateMenu',
    }),
    onClickAddToMenuOpen() {
      this.addToMenu.menuType = this.menusToAdd.length ? 'select' : 'new'
      this.addToMenu.menuName = ''
      this.addToMenu.menuId = null

      this.addToMenu.categoryType = 'new'
      this.addToMenu.categoryName = ''
      this.addToMenu.categoryId = null

      this.addToMenu.isModal = true
    },
    onClickAddToMenuMenuType(type) {
      this.addToMenu.menuName = ''
      this.addToMenu.menuId = null

      this.addToMenu.categoryType = type
      this.addToMenu.categoryName = ''
      this.addToMenu.categoryId = null
    },
    async onClickAddToMenuSave() {
      this.addToMenu.isInProgress = true

      const createOfferResponse = await this.createOffer(this.$route.params.id)[0]

      let menu = null
      if (this.addToMenu.menuType === 'new') {
        menu = await this.createMenu(this.addToMenu.menuName)
      }
      if (this.addToMenu.menuType === 'select') {
        menu = this.menus.find(i => i.id === this.addToMenu.menuId)
      }

      let category = null
      if (this.addToMenu.categoryType === 'new') {
        const response = await this.createCategory(this.addToMenu.categoryName)[0]
        category = response.data.createCategory
      }
      if (this.addToMenu.categoryType === 'select') {
        category = this.menus
          .find(i => i.id === this.addToMenu.menuId).categories
          .find(i => i.id === this.addToMenu.categoryId)
      }
      await this.updateCategory(
        category.id,
        {
          items: [...category.items.map(i => i.id), createOfferResponse.data.createOffer.id],
        },
      )[0]

      await this.$apollo.mutate({
        mutation: UPDATE_MENU,
        variables: {
          updateMenuId: menu.id,
          categories: [...new Set([...menu.categories.map(i => i.id), category.id])],
        },
      })

      await Promise.all([
        this.getMenus(),
        this.getMenuItems(),
      ])

      this.$emit('reset')

      this.addToMenu.isModal = false
      this.addToMenu.isInProgress = false
    },
    onUpdateValue([offerId, fieldName, value], offerTreeId) {
      const emitPayload = this.value.map(i => {
        if (i.id === offerTreeId) {
          return {
            ...i,
            categories: i.categories.map(j => {
              if (j.id === i.categories[0].id) {
                return {
                  ...j,
                  items: j.items.map(k => {
                    if (k.id === offerId) {
                      return {
                        ...k,
                        [fieldName]: value,
                      }
                    }
                    return k
                  }),
                }
              }
              return j
            }),
          }
        }
        return i
      })
      this.$emit('input', emitPayload)
    },
    onDelete(offerTree) {
      const menu = this.menus.find(i => i.id === offerTree.id)
      const categoryId = offerTree.categories[0].id

      const categoryItems = menu.categories.find(i => i.id === categoryId).items
      if (categoryItems.length === 1) {
        const categories = menu.categories.filter(i => i.id !== categoryId)

        this.requests.push(
          () => [
            this.$apollo.mutate({
              mutation: UPDATE_MENU,
              variables: {
                updateMenuId: offerTree.id,
                categories: categories.map(i => i.id),
              },
            }),
          ],
          () => categoryItems.map(i => i.id).flatMap(i => this.deleteOffer(i)),
          () => this.deleteCategory(categoryId),
        )

        this.updateMenu([offerTree.id, 'content', menu.content.filter(i => i.id !== categoryId)])
        this.updateMenu([offerTree.id, 'categories', categories])
      } else {
        const offerId = offerTree.categories[0].items[0].id
        const categories = menu.categories.map(i => {
          if (i.id === categoryId) {
            return {
              ...i,
              content: i.content.filter(j => j.id !== offerId),
              items: i.items.filter(j => j.id !== offerId),
            }
          }
          return i
        })

        this.requests.push(
          () => this.updateCategory(
            categoryId,
            {
              items: categories.find(i => i.id === categoryId).items.map(i => i.id),
            },
          ),
          () => this.deleteOffer(offerId),
        )

        this.updateMenu([offerTree.id, 'categories', categories])
      }

      this.$emit('input', this.value.filter(i => i.id !== offerTree.id))
    },
  },
}
</script>
