<template>
  <b-card-actions
    :title="$t('cardSuggestionsTitle')"
    action-collapse
    collapsed
  >
    <template v-slot:after-title>
      <feather-icon
        icon="InfoIcon"
        role="button"
        size="20"
        style="vertical-align: top"
        class="ml-25 text-primary toggle-content"
        @click.stop="setIsInfo([page, 'suggestions'])"
      />
    </template>
    <b-collapse v-model="isInfo[page].suggestions">
      <small class="d-block mb-2">
        {{ $t('infoSuggestions') }}
      </small>
    </b-collapse>
    <b-form-group :label="$t('cardSuggestionsNameLabel')">
      <validation-observer ref="name">
        <validation-provider
          #default="{ errors, failedRules }"
          name="suggestions_name"
          :rules="`max:${textLimit}`"
        >
          <b-form-input
            v-b-tooltip.hover.top="$t('cardSuggestionsNameTooltip')"
            :value="name"
            :placeholder="$t('cardSuggestionsNamePlaceholder')"
            :state="errors.length ? false : null"
            @input="$emit('inputName', $event)"
            @blur="onBlurName"
          />
          <small
            v-if="errors.length"
            class="text-danger"
          >
            <template v-if="failedRules.max">
              {{ $t('validationErrorMaxSuggestionsName', { slot: textLimit }) }}
            </template>
            <template v-else>{{ errors[0] }}</template>
          </small>
          <div
            v-else
            class="text-right"
          >
            <small>{{ name.length }}/{{ textLimit }}</small>
          </div>
        </validation-provider>
      </validation-observer>
    </b-form-group>
    <treeselect
      id="treeselect-suggestions"
      :value="items"
      :multiple="true"
      :disable-branch-nodes="true"
      :options="optionsTreeselectSuggestions"
      :clearable="false"
      :placeholder="$t('Select from existing items')"
      value-format="object"
      @input="$emit('inputItems', $event)"
      @close="$emit('saveItems')"
    />
    <b-tooltip target="treeselect-suggestions">
      <template v-if="isSubscriptionPremium">
        {{ $t('tooltipSuggestionsPremium') }}
      </template>
      <template v-else>
        <div class="pt-50 pb-50">
          <span>{{ $t('tooltipSuggestions') }}</span>
          <b-button
            block
            size="sm"
            class="mt-50"
            variant="primary"
            @click="$router.push({ name: 'billing' })"
          >
            {{ isSubscriptionInactive ? $t('Start Free Trial') : $t('Upgrade') }}
          </b-button>
        </div>
      </template>
    </b-tooltip>
    <b-row
      v-if="items.length"
      class="mt-1"
    >
      <b-col>
        <b-form-group
          :label="$t('cardSuggestionsItemsLabel')"
          class="mb-0"
        />
      </b-col>
    </b-row>
    <draggable
      v-model="items"
      @end="$emit('saveItems')"
    >
      <b-row
        v-for="(item, itemIndex) in items"
        :key="item.id"
        :class="[{ 'mt-50': itemIndex !== 0 }]"
      >
        <b-col>
          <b-link
            :to="{ name: item.type === 'MenuItem' ? 'menu-item' : 'combination-menu-item', params: { id: item.id } }"
          >
            {{ item.name }}
          </b-link>
        </b-col>
        <b-col
          cols="auto"
          class="d-flex align-items-center"
        >
          <feather-icon
            size="20"
            role="button"
            icon="XCircleIcon"
            @click="onClickDeleteItem(item.id)"
          />
        </b-col>
      </b-row>
    </draggable>
  </b-card-actions>
</template>

<script>
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import {
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BLink,
  BCollapse,
  VBTooltip,
  BTooltip,
  BButton,
} from 'bootstrap-vue'
import Treeselect from '@riophae/vue-treeselect'
import { mapGetters, mapActions } from 'vuex'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { max } from '@validations'
import mixinCardCollapsed from '@/mixins/card-collapsed'
import draggable from 'vuedraggable'

export default {
  name: 'CardSuggestions',
  components: {
    BCardActions,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BLink,
    BCollapse,
    Treeselect,
    BTooltip,
    BButton,
    draggable,

    // validations
    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [
    mixinCardCollapsed,
  ],
  props: {
    name: {
      type: String,
      required: true,
      default: '',
    },
    items: {
      type: Array,
      required: true,
      default: () => [],
    },
    page: {
      type: String,
      required: true,
      default: '',
    },
    textLimit: {
      type: Number,
      required: true,
      default: 100,
    },
  },
  data() {
    return {
      // validation rules
      max,
    }
  },
  computed: {
    ...mapGetters({
      isInfo: 'main/isInfo',
      menuItems: 'menuManagement/menuItems',
      subscription: 'billing/subscription',
      isSubscriptionInactive: 'billing/isSubscriptionInactive',
      isSubscriptionPremium: 'billing/isSubscriptionPremium',
    }),
    optionsTreeselectSuggestions() {
      return this.menuItems
        .filter(i => i.id !== this.$route.params.id)
        .map(i => ({
          ...i,
          label: i.name,
          isDisabled: !this.isSubscriptionPremium,
        }))
    },
  },
  methods: {
    ...mapActions({
      setIsInfo: 'main/setIsInfo',
    }),
    async onBlurName() {
      const isValid = await this.$refs.name.validate()
      if (isValid) {
        this.$emit('saveName')
      }
    },
    onClickDeleteItem(id) {
      this.$emit('inputItems', this.items.filter(i => i.id !== id))
      this.$emit('saveItems')
    },
  },
}
</script>

<style lang="sass">
  @import '@/assets/sass/treeselect.sass'
</style>
